import { safeSmoothScrollTo } from '@florencecard-lib/scroll';
import { ua } from '@florencecard-lib/ua';
import { useObservable, useSubscription } from 'observable-hooks';
import { animationFrameScheduler, EMPTY, fromEvent, merge, of } from 'rxjs';
import { debounceTime, filter, map, observeOn, share, switchMap, takeUntil } from 'rxjs/operators';
import { useLocationChanges$ } from './routing';

const DEFAULT_PADDING = 110;

interface TextFieldFocusTweakOptions {
  padding?: number;
}

const windowResize$ = ua.isBrowser ? fromEvent(window, 'resize').pipe(share()) : EMPTY;
const visualViewportResize$ =
  ua.isBrowser && window.visualViewport != null
    ? fromEvent(window.visualViewport, 'resize').pipe(share())
    : EMPTY;
const focusin$ = ua.isBrowser ? fromEvent(document, 'focusin').pipe(share()) : EMPTY;
const focusout$ = ua.isBrowser ? fromEvent(document, 'focusout').pipe(share()) : EMPTY;

const targetTagNames = ['TEXTAREA', 'INPUT', 'SELECT'];
const isValidTarget = (target: EventTarget | Element | null): target is Element => {
  if (target == null || !('tagName' in target)) {
    return false;
  }

  const tagName = target.tagName;
  if (!targetTagNames.includes(tagName)) {
    return false;
  }

  if (tagName === 'INPUT') {
    const type = (target as HTMLInputElement)?.type;

    return !(type === 'checkbox' || type === 'radio');
  }

  return true;
};

export function useTextFieldFocusTweak(options: TextFieldFocusTweakOptions = {}) {
  const { padding = DEFAULT_PADDING } = options;

  const locationChanges$ = useLocationChanges$();
  const focusTarget$ = useObservable(() =>
    ua.isAndroid || ua.isIos
      ? merge(
          windowResize$.pipe(map(() => document.activeElement)),
          visualViewportResize$.pipe(map(() => document.activeElement)),
          focusin$.pipe(map((event) => event.target)),
        ).pipe(
          observeOn(animationFrameScheduler),
          filter(isValidTarget),
          switchMap((target) => of(target).pipe(debounceTime(300), takeUntil(locationChanges$))),
        )
      : EMPTY,
  );

  useSubscription(focusTarget$, (target) => {
    const viewportHeight = window.visualViewport
      ? window.visualViewport.height
      : window.innerHeight;
    const viewportBottom = viewportHeight - padding;

    const targetBottom = target.getBoundingClientRect().bottom;
    const bottomDifference = targetBottom - viewportBottom;

    if (bottomDifference >= 0) {
      safeSmoothScrollTo(window, window.scrollY + bottomDifference);
    }
  });
}

/**
 * @description 텍스트 필드가 있는 페이지에 한 번만 사용해주세요.
 * @description iOS에서 Input 혹은 Textarea 같은 필드를 사용할 경우 포커스 발생시 키보드가 올라오는데,
 * 포커스 해제시, WKWebView 내에서는 해당 키보드로 올라온 영역이 다시 사라지지 않고 남아있어 Fixed element 전체에 버그가 발생합니다.
 * 이 문제를 해결하기 위해 포커스 해제시 강제 스크롤을 발생시켜 버그를 우회하는 hook입니다.
 */
export function useIOSTextFieldTweak() {
  const locationChanges$ = useLocationChanges$();
  const focusTarget$ = useObservable(() =>
    ua.isIos
      ? merge(focusout$.pipe(map((event) => event.target))).pipe(
          observeOn(animationFrameScheduler),
          filter(isValidTarget),
          switchMap((target) => of(target).pipe(debounceTime(300), takeUntil(locationChanges$))),
        )
      : EMPTY,
  );

  useSubscription(focusTarget$, () => {
    safeSmoothScrollTo(window, window.scrollY);
  });
}
