import Router from 'next/router';
import { useEffect, useMemo } from 'react';
import { Subject } from 'rxjs';

let historyListened = false;
const _historyChanges$ = new Subject<string>();

export function useLocationChanges$() {
  const historyChange$ = useMemo(() => _historyChanges$.asObservable(), []);

  useEffect(() => {
    if (historyListened) {
      return;
    }

    const listener = (url: string) => {
      _historyChanges$.next(url);
    };

    Router.events.on('routeChangeComplete', listener);

    return () => {
      Router.events.off('routeChangeComplete', listener);
      historyListened = false;
    };
  }, []);

  return historyChange$;
}
