import { useObservable, useSubscription } from 'observable-hooks';
import { useEffect, useState } from 'react';
import { of } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { polling } from '~/utils';

interface Options<T> {
  scripts: Array<{
    id: string;
    src: string;
    async?: boolean;
  }>;
  /** @default 10 */
  pollingCount?: number;
  checkIsReady(): boolean;
  initialize(): T | null | undefined | Promise<T | null | undefined>;
  onError?(error: Error): void;
  onTimeout?(): void;
}

export function useVendorJS<T>(options: Options<T>) {
  const { scripts, pollingCount = 10, checkIsReady, initialize, onError, onTimeout } = options;

  const [data, setData] = useState<T | null>(null);
  const [available, setAvailable] = useState(false);
  const [error, setError] = useState<Error | null>(null);
  const [timeout, setTimeout] = useState(false);

  const available$ = useObservable(
    (input$) =>
      input$.pipe(
        switchMap(([pollingCount, checkIsReady]) =>
          polling(() => of(checkIsReady()), {
            count: pollingCount,
          }),
        ),
      ),
    [pollingCount, checkIsReady],
  );

  const subscription = useSubscription(
    available$,
    async (initialized) => {
      if (initialized) {
        subscription.current?.unsubscribe();

        const data = await initialize();
        setData(data ?? null);
        setAvailable(data != null);
      }
    },
    (error) => {
      setError(error);
      setAvailable(false);
      onError?.(error);
    },
    () => {
      setTimeout(true);
      setAvailable(false);
      onTimeout?.();
    },
  );

  useEffect(() => {
    scripts.map((script) => {
      if (document.getElementById(script.id) != null) {
        return;
      }

      const scriptElem = document.createElement('script');
      scriptElem.id = script.id;
      scriptElem.src = script.src;
      scriptElem.async = Boolean(script.async);

      document.head.appendChild(scriptElem);
    });
  }, [scripts]);

  return [data, available, error, timeout] as const;
}
