import { safeScrollTo } from '@florencecard-lib/scroll';
import { ua } from '@florencecard-lib/ua';
import { useSubscription } from 'observable-hooks';
import { useLocationChanges$ } from '~/core/hooks/routing';

export function useScrollToTopWhenPageChanged() {
  const changes$ = useLocationChanges$();

  useSubscription(changes$, () => {
    if (ua.isBrowser) {
      safeScrollTo(window, { top: 0 });
    }
  });
}
