import { parseQueryString } from '@florencecard-lib/query-string';
import { ua } from '@florencecard-lib/ua';
import { useMemo } from 'react';

export function useQueryParam<T extends string = string>(name: string) {
  return useMemo(() => {
    if (!ua.isBrowser) {
      return undefined;
    }

    const params = parseQueryString<{ [key: string]: T | undefined }>();
    return params[name];
  }, [name]);
}
