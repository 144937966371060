import { ua } from '@florencecard-lib/ua';
import { useEffect, useMemo, useState } from 'react';
import { asapScheduler, BehaviorSubject, fromEvent, merge, Observable } from 'rxjs';
import { debounceTime, filter, map, share, startWith } from 'rxjs/operators';

function noopMatchMedia(query: string): MediaQueryList {
  return {
    matches: query === 'all' || query === '',
    media: query,
    addListener: () => {},
    removeListener: () => {},
  } as any; // eslint-disable-line @typescript-eslint/no-explicit-any
}

export const matchMedia =
  ua.isBrowser && typeof window.matchMedia === 'function'
    ? // matchMedia is bound to the window scope intentionally as it is an illegal invocation to
      // call it from a different scope.
      window.matchMedia.bind(window)
    : noopMatchMedia;

const isQuery = (queryOrEmpty: string | undefined): queryOrEmpty is string =>
  queryOrEmpty !== undefined;

let windowChanges$: Observable<unknown> | null = null;

function getWindowChanges() {
  if (windowChanges$ != null) {
    return windowChanges$;
  }

  windowChanges$ = merge(fromEvent(window, 'resize'), fromEvent(window, 'orientationchange')).pipe(
    debounceTime(0, asapScheduler),
    share(),
  );

  return windowChanges$;
}

export function useMediaQuery(query: string, deferOnStart = true) {
  const [isMatched, setMatchedState] = useState(
    deferOnStart ? undefined : matchMedia(query).matches,
  );
  const queries = useMemo(() => new BehaviorSubject<string | undefined>(undefined), []);

  useEffect(() => {
    queries.next(query);
  }, [queries, query]);

  useEffect(() => {
    const windowChanges = getWindowChanges().pipe(map(() => queries.value));

    const subscription = merge(windowChanges, queries.asObservable())
      .pipe(startWith(queries.value), filter(isQuery), map(matchMedia))
      .subscribe(({ matches }) => {
        setMatchedState(matches);
      });

    return () => {
      subscription.unsubscribe();
      queries.complete();
    };
  }, [queries]);

  return isMatched;
}
